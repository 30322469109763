import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import BackgroundImg from 'gatsby-background-image'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ExitToIcon from '@material-ui/icons/ExitToApp'
import HeroBox from '../../../../components/herobox'
import Layout from '../../../../components/layout'
import SEO from '../../../../components/seo'
import Paragraphs from '../../../../components/paragraph'
import Heading from '../../../../components/heading'
import GalleryTakaoStation from '../../../../components/gallery/takao/stationZhHans'
import GalleryTakaoMtTakao from '../../../../components/gallery/takao/mt_takaoZhHans'

const useStyles = makeStyles(theme => ({
    root: {

    },
    rootContainer: {
        padding: 0,
    },
    galleryContainer: {
        padding: '15px',
    },
    hero: {
        padding: '1rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '80vh',
        textAlign: 'center',
        color: '#ffffff',
    },
    heading1: {
        backgroundColor: 'rgba(72,189,152,.9)',
        padding: '20px;'
    },
    paragraphs: {
        paddingTop: '30px;'
    },
    paragraph: {
        backgroundColor: 'rgba(0,0,0,.5)',
    },
    button: {
        textAlign: 'right',
        marginBottom: '1.45rem',
        paddingRight: '30px',
    }
}));

const TakaoStationIndexPage = ({ data }) => {
    const classes = useStyles();
    return (
        <Layout language="zh_hans" slug="station/takao">
            <SEO title={data.stationsJson.pages.zh_hans.stationName} />
            <HeroBox
                image={data.hero.childImageSharp.fluid}
                stationName={data.stationsJson.pages.zh_hans.stationName}
                descriptions={data.stationsJson.pages.zh_hans.descriptions}
            />
            {/* <BackgroundImg
                Tag="section"
                fluid={data.hero.childImageSharp.fluid}
                backgourndColor={`#48bd98`}
                style={{
                    minHeight: '80vh',
                    backgroundSize: 'auto',
                }}
            >
                <section className={classes.hero}><div>
                    <h1><span className={classes.heading1}>{data.stationsJson.pages.zh_hans.stationName}</span></h1>
                    <div className={classes.paragraphs}>
                        {
                            data.stationsJson.pages.zh_hans.descriptions.map((description, i) => (
                                <p key={i}><span className={classes.paragraph}>{description}</span></p>
                            ))
                        }
                    </div>
                </div></section>
            </BackgroundImg> */}
            <Container component="section" className={classes.rootContainer}>
                <Box component="article">{
                    data.stationsJson.pages.zh_hans.sections.map((section, i) => {
                        if (!section.isChild) {
                            if ('article' === section.type) {                                  
                                    if (section.link) {
                                        return (
                                            <section key={i}>
                                                <Heading title={section.title} isChild={section.isChild} />
                                                <Paragraphs paragraphs={section.paragraphs} />
                                                <div className={classes.button}>
                                                    <Button variant="outlined" color="primary" href={section.link}>
                                                        {section.title} <ExitToIcon />
                                                    </Button>
                                                </div>
                                            </section>
                                        )
                                    } else {
                                        return (
                                            <section key={i}>
                                                <Heading title={section.title} isChild={section.isChild} />
                                                <Paragraphs paragraphs={section.paragraphs} />
                                            </section>
                                        )
                                    }
                            } else if ('gallery' === section.type) {
                                if ('stations/takao/station' === section.gallery.directory) {
                                    return (
                                        <section className={classes.galleryContainer} key={i}>
                                            <GalleryTakaoStation idxSection={i} language={data.stationsJson.pages.zh_hans.language} />
                                        </section>
                                    )
                                } else if ('stations/takao/mt_takao' === section.gallery.directory) {
                                    return (
                                        <section className={classes.galleryContainer} key={i}>
                                            <GalleryTakaoMtTakao idxSection={i} language={data.stationsJson.pages.zh_hans.language} />
                                        </section>
                                    )
                                } else {

                                }
                            } else if ('link' === section.type) {

                            } else {

                            }
                        } else {
                            if ('article' === section.type) {
                                if (section.link) {
                                    return (
                                        <section key={i}>
                                            <Heading title={section.title} isChild={section.isChild} />
                                            <Paragraphs paragraphs={section.paragraphs} />
                                            <div className={classes.button}>
                                                <Button variant="outlined" color="primary" href={section.link}>
                                                    {section.title} <ExitToIcon />
                                                </Button>
                                            </div>
                                        </section>
                                    )
                                } else {
                                    return (
                                        <section key={i}>
                                            <Heading title={section.title} isChild={section.isChild} />
                                            <Paragraphs paragraphs={section.paragraphs} />
                                        </section>
                                    )
                                }
                            } else if ('gallery' === section.type) {
                                if ('stations/takao/station' === section.gallery.directory) {
                                    return (
                                        <section className={classes.galleryContainer} key={i}>
                                            <GalleryTakaoStation idxSection={i} language={data.stationsJson.pages.zh_hans.language} />
                                        </section>
                                    )
                                } else if ('stations/takao/mt_takao' === section.gallery.directory) {
                                    return (
                                        <section className={classes.galleryContainer} key={i}>
                                            <GalleryTakaoMtTakao idxSection={i} language={data.stationsJson.pages.zh_hans.language} />
                                        </section>
                                    )
                                } else {

                                }
                            } else if ('link' === section.type) {
                                // return (
                                //     <section key={i}>
                                //         <div className={classes.button}>
                                //             <Button variant="outlined" color="primary" href={section.link}>
                                //                 {section.title} <ExitToIcon />
                                //             </Button>
                                //         </div>
                                //     </section>
                                // )
                            } else {

                            }
                        }
                    })
                }</Box>
            </Container>
            
        </Layout>
    );
};

export const query = graphql`{
    stationsJson(name: {eq: "takao"}) {
        id
        name
        lat
        lon
        route_id
        prefecture_id
        zip
        address
        heroImage
        pages {
            zh_hans {
            descriptions
            language
            stationName
            sections {
                isChild
                link
                paragraphs
                title
                type
                gallery {
                directory
                items {
                    fileName
                    title
                }
                }
            }
            }
        }
    }
    hero:file(relativePath: {eq: "stations/takao/hero.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1000, maxHeight: 1000, jpegQuality: 100) {
                ...GatsbyImageSharpFluid
            }

        }
    }
}`

export default TakaoStationIndexPage